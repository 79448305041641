import React, { useEffect, useState, useRef } from 'react'
import Header from '../../Utilities/Header/Header'
import Footer from '../../Utilities/Footer/Footer'
import about from "../../Images/logo/about.png"
import instagram from "../../Images/logo/footer/instagram_2111463.png"
import linkedin from "../../Images/logo/footer/linkedin_2111499.png"
import google from "../../Images/logo/footer/google_300221.png"
import facebook from "../../Images/logo/footer/facebook_3670032.png"
import gallery1 from "../../Images/logo/gallery/gallery-1.png"
import gallery2 from "../../Images/logo/gallery/gallery-2.png"
import gallery3 from "../../Images/logo/gallery/gallery-3.png"
import gallery4 from "../../Images/logo/gallery/gallery-4.png"
import gallery5 from "../../Images/logo/gallery/gallery-5.png"
import gallery6 from "../../Images/logo/gallery/gallery-6.png"
import gallery7 from "../../Images/logo/gallery/gallery-7.png"
import gallery8 from "../../Images/logo/gallery/gallery-8.png"
import gallery9 from "../../Images/logo/gallery/gallery-9.png"
import slide1 from "../../Images/logo/slide/slide-1.png"
import slide2 from "../../Images/logo/slide/slide-2.png"
import slide3 from "../../Images/logo/slide/slide-3.png"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'
import p1 from "../../Images/logo/Mockups/1.png"
import p2 from "../../Images/logo/Mockups/2.png"
import p3 from "../../Images/logo/Mockups/3.png"
import p4 from "../../Images/logo/Mockups/4.png"
import p5 from "../../Images/logo/Mockups/5.png"
import p6 from "../../Images/logo/Mockups/6.png"
import p7 from "../../Images/logo/Mockups/7.png"
import p8 from "../../Images/logo/Mockups/8.png"
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fb from "../../Firebase/firebase";
const DB = fb.firestore()
const CF = DB.collection('Vak_International_Form');



const Home = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const [imageSrc, setImageSrc] = useState("");
  const [closeZoom, setCloseZoom] = useState("none");

  const close = () => {
    setCloseZoom("none");
  }

  let isrc;
  const fetchsrc = (event) => {
    // e.preventDefault();
    isrc = event.target.src;
    setImageSrc(isrc);
    setCloseZoom("flex");
  }

  const items = [
    <div className="item" data-value="1" id='a001'>
      <img src={gallery1} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="2" id='002'>
      <img src={gallery2} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="3" id='003'>
      <img src={gallery3} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="4" id='004'>
      <img src={gallery4} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="5" id='005'>
      <img src={gallery5} alt="" onClick={fetchsrc} /><svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="5" id='006'>
      <img src={gallery6} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="5" id='007'>
      <img src={gallery7} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="5" id='008'>
      <img src={gallery8} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
    <div className="item" data-value="5" id='009'>
      <img src={gallery9} alt="" onClick={fetchsrc} />
      <svg id="Capa_1" enable-background="new 0 0 426.667 426.667" height="512" viewBox="0 0 426.667 426.667" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m277.333 170.667c5.658-.001 11.083-2.25 15.083-6.251l91.584-91.584v55.168c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-106.667c.001-11.782-9.551-21.333-21.333-21.333h-106.666c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h55.168l-91.584 91.584c-8.33 8.332-8.328 21.84.005 30.17 3.999 3.999 9.422 6.245 15.077 6.247z" /><path d="m21.333 426.667h106.667c11.782 0 21.333-9.551 21.333-21.333s-9.551-21.334-21.333-21.334h-55.168l91.584-91.584c8.185-8.475 7.95-21.98-.524-30.165-8.267-7.985-21.374-7.985-29.641 0l-91.584 91.584v-55.168c0-11.782-9.551-21.333-21.333-21.333s-21.334 9.551-21.334 21.333v106.667c0 11.781 9.551 21.333 21.333 21.333z" /></g></svg>
    </div>,
  ];

  // slider 
  const handleDragStart = (e) => e.preventDefault();

  const items2 = [
    <div className="slider-body">
      <img src={slide1} onDragStart={handleDragStart} role="presentation" />
      <div className="slider-text">
        <h3><span>Empowering Global Health with Excellence</span></h3>
        <p>Our commitment to excellence fuels our mission to empower global health.</p>
      </div>
    </div>,
    <div className="slider-body">
      <img src={slide2} onDragStart={handleDragStart} role="presentation" />
      <div className="slider-text">
        <h3><span>Innovation Enriching Lives</span></h3>
        <p>Our innovative approach enriches lives by addressing unmet medical needs.</p>
      </div>
    </div>,
    <div className="slider-body">
      <img src={slide3} onDragStart={handleDragStart} role="presentation" />
      <div className="slider-text">
        <h3><span>Healthcare with Integrity, Worldwide</span></h3>
        <p> We deliver high-quality healthcare solutions with integrity, transcending geographical boundaries.</p>
      </div>
    </div>,
  ];

  // typing effect in slider text

  // slider ends


  const form = useRef();
  const [userData, setUserData] = useState({
    Name: "",
    Email: "",
    Mobile: "",
    Message: "",
    RetailerSelfUse: ""
  });

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  }

  //connect with firebase
  const SubmitData = async (e) => {
    e.preventDefault();
    const { Name, Email, Subject, Message } = userData;
    if (Name && Email && Subject && Message) {

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(userData.Email)) {
        toast.warning('Please enter a valid email address');
        return
      }

      try {

        CF.add({
          Name: Name,
          Email: Email,
          Subject: Subject,
          Message: Message
        }).then((docRef) => {
          toast.success('Message Sent Successfully');
        }).catch((error) => {
          console.error("error:", error);
        });


        // emailjs.sendForm('service_91sqknx', 'template_4j6wwya', form.current, '6pbmyufjSURA60XeS')
        //   .then((result) => {
        //     console.log(result.text);

        //   }, (error) => {
        //     console.log(error.text);
        //   });

        setUserData({
          Name: "",
          Email: "",
          Subject: "",
          Message: ""
        })

        // console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
        toast.warning("Error adding document")
      }
    }
    else {
      toast.warning('Please fill the data');
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <>
        <ToastContainer />
        {/* ======= Header ======= */}
        <Header />
        {/* End Header */}
        {/* ======= Hero Section ======= */}
        <div className="slider">
          {/* <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={4000} swipeable={false} showIndicators={false}>
            <div className="team-slider">
              <div className="team-slider-text">
                <h3><span>Lorem ipsum</span></h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis vero impedit harum.</p>

              </div>
              <div className="team-slider-img">
                <img src={slide1} alt="team" />
              </div>
            </div>
            <div className="team-slider">
              <div className="team-slider-text">
                <h3><span>Lorem ipsum</span></h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis vero impedit harum. </p>

              </div>
              <div className="team-slider-img">
                <img src={slide2} alt="team" />
              </div>
            </div>
            <div className="team-slider">
              <div className="team-slider-text">
                <h3><span>Lorem ipsum</span></h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis vero impedit harum. </p>

              </div>
              <div className="team-slider-img">
                <img src={slide3} alt="team" />
              </div>
            </div>
          </Carousel> */}

          <AliceCarousel
            mouseTracking
            items={items2}
            autoPlay={true}
            infinite={true}
            autoPlayInterval={5000}
            animationDuration={1000}
            renderPrevButton={() => {
              return <svg className='prev' id="Layer_1" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 .4c-6.4 0-11.6 5.2-11.6 11.6s5.2 11.6 11.6 11.6 11.6-5.2 11.6-11.6-5.2-11.6-11.6-11.6zm-1.4 15.4-.6-.7 3-3.1-3-3.1.7-.7 3.7 3.7z" /></svg>
            }}
            renderNextButton={() => {
              return <svg className='next' id="Layer_1" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 .4c-6.4 0-11.6 5.2-11.6 11.6s5.2 11.6 11.6 11.6 11.6-5.2 11.6-11.6-5.2-11.6-11.6-11.6zm-1.4 15.4-.6-.7 3-3.1-3-3.1.7-.7 3.7 3.7z" /></svg>
            }} />

        </div>
        {/* End Hero */}
        <main id="main">
          {/* ======= Featured Services Section ======= */}
          {/* <section id="featured-services" className="featured-services">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}>
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                      <div className="icon">
                        <svg id="Capa_1" enable-background="new 0 0 512.039 512.039" height="512" viewBox="0 0 512.039 512.039" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m255.874 67.497c-111.609-97.618-253.518-11.848-255.845 104.427-.593 29.633 7.961 58.498 24.988 85.109h110.364l19.63-32.718c5.7-9.499 19.494-9.805 25.517-.335l41.417 65.083 60.373-127.451c5.265-11.121 20.956-11.474 26.763-.69l51.752 96.111h125.898c93.262-145.76-91.055-311.811-230.857-189.536z" /><path d="m338.667 279.144-41.936-77.881-59.301 125.19c-5.045 10.651-19.884 11.576-26.211 1.632l-42.97-67.523-11.513 19.188c-2.711 4.518-7.593 7.282-12.862 7.282h-95.128c2.982 3.121-12.911-12.74 196.548 195.634 5.85 5.821 15.307 5.822 21.158 0 206.236-205.168 193.572-192.519 196.548-195.634h-111.126c-5.519.001-10.591-3.029-13.207-7.888z" /></g></svg>
                      </div>
                      <h4 className="title">
                        <a href="">Lorem Ipsum</a>
                      </h4>
                      <p className="description">
                        Voluptatum deleniti atque corrupti quos dolores et quas
                        molestias excepturi
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}>
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
                      <div className="icon">
                        <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m507.607 103.389-98.995-98.995c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l17.678 17.678-42.427 42.426 63.64 63.64 42.427-42.427 17.678 17.678c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.465 10.606-4.394c5.858-5.858 5.858-15.356 0-21.213z" /><path d="m295.475 60.962c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l10.607 10.607-21.213 21.213 38.891 38.891c5.858 5.857 5.858 15.355 0 21.213-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.465-10.606-4.394l-38.891-38.891-21.214 21.214 38.891 38.891c5.858 5.857 5.858 15.355 0 21.213-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.465-10.606-4.394l-38.891-38.891-21.214 21.214 134.35 134.35 106.067-106.067 10.607 10.607c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.465 10.606-4.394c5.858-5.857 5.858-15.355 0-21.213z" /><path d="m136.376 241.274 38.891 38.891c5.858 5.857 5.858 15.355 0 21.213-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.465-10.606-4.394l-38.891-38.891-10.607 10.607c-17.546 17.546-17.546 46.094 0 63.64l70.711 70.711c8.772 8.773 20.296 13.159 31.819 13.159 11.524 0 23.048-4.387 31.82-13.159l53.033-53.033-134.35-134.35z" /><path d="m62.13 379.16c-5.858 5.858-5.858 15.355 0 21.213l14.142 14.142-71.878 71.879c-5.858 5.857-5.858 15.355 0 21.213 2.929 2.928 6.767 4.393 10.606 4.393s7.678-1.465 10.606-4.394l71.878-71.878 14.142 14.142c5.858 5.858 15.355 5.858 21.213 0l21.213-21.213-70.71-70.711z" /></g></svg>                    </div>
                      <h4 className="title">
                        <a href="">Sed ut perspiciatis</a>
                      </h4>
                      <p className="description">
                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}>
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
                      <div className="icon">
                        <svg id="Layer_1" enable-background="new 0 0 48 48" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m24 0c-5.747 0-10.421 4.676-10.421 10.424 0 5.75 4.675 10.427 10.421 10.427s10.422-4.677 10.422-10.427c0-5.748-4.676-10.424-10.422-10.424z" /><path d="m32.053 23.793c.371 1.605.957 4.523.762 6.215 1.533 1.359 5.555 5.365 4.68 9.318-.006.047-.42 2.32-3.24 3.285-.107.037-.217.055-.324.055-.416 0-.803-.262-.945-.678-.18-.523.1-1.092.623-1.27 1.678-.574 1.922-1.764 1.926-1.775.598-2.711-2.402-6.002-4.07-7.465-1.996.969-5.77 3.346-5.916 6.068-.006.084-.068 1.289 1.375 2.271.457.311.576.934.264 1.391-.193.283-.508.438-.826.438-.195 0-.391-.057-.563-.174-2.467-1.678-2.254-3.986-2.245-4.084.227-4.225 5.749-7.104 7.29-7.828.092-1.5-.531-4.598-.99-6.348-.014-.055-.004-.108-.01-.163-1.455-.396-2.98-.626-4.561-.626h-2.567c-1.77 0-3.478.27-5.089.763-.017.071-.021.145-.056.214-.022.045-2.187 4.539-3.222 11.412 1.739.48 3.027 2.059 3.027 3.947 0 2.266-1.844 4.109-4.109 4.109-2.267 0-4.111-1.844-4.111-4.109 0-1.943 1.361-3.568 3.178-3.992.703-4.842 1.929-8.529 2.713-10.541-5.768 2.844-9.756 8.771-9.756 15.627v7.147c0 .553.447 1 1 1h35.422c.553 0 1-.461 1-1.014v-7.133c-.002-7.21-4.402-13.412-10.66-16.06z" /></g></svg>
                      </div>
                      <h4 className="title">
                        <a href="">Magni Dolores</a>
                      </h4>
                      <p className="description">
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa
                        qui officia
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}>
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={400}>
                      <div className="icon">
                        <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m336.333 512h-160.666c-8.284 0-15-6.716-15-15v-145.667h-145.667c-8.284 0-15-6.716-15-15v-160.666c0-8.284 6.716-15 15-15h145.667v-145.667c0-8.284 6.716-15 15-15h160.666c8.284 0 15 6.716 15 15v145.667h145.667c8.284 0 15 6.716 15 15v160.666c0 8.284-6.716 15-15 15h-145.667v145.667c0 8.284-6.716 15-15 15z" /></svg>
                      </div>
                      <h4 className="title">
                        <a href="">Nemo Enim</a>
                      </h4>
                      <p className="description">
                        At vero eos et accusamus et iusto odio dignissimos ducimus qui
                        blanditiis
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </section> */}
          {/* End Featured Services Section */}

          {/* ======= About Us Section ======= */}
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>About Us</h2>
              </div>
              <div className="row">

                <div className="col-lg-6 image " data-aos="fade-right">
                  <AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
                    <img src={about} className="img-fluid" alt="about" />
                  </AnimationOnScroll>

                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
                  <AnimationOnScroll animateIn="animate__slideInRight" animateOnce={true}>

                    <p>
                      VAK International Pharmaceuticals was founded in 2023 by Vibha Singh with a mission to improve global health by offering top-notch pharmaceutical and nutraceutical products. They believe in innovation, excellence, and integrity to make a meaningful impact on people's lives through a diverse range of healthcare solutions.

                    </p>
                    {/* <ul>
                      <li>
                        <div className="svg">
                          <svg height="512" viewBox="0 0 520 520" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_7-Check" data-name="7-Check"><path d="m79.423 240.755a47.529 47.529 0 0 0 -36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515l250.787-403.892c.041-.067.084-.134.128-.2 2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0 -19.362 1.343q-.135.166-.278.327l-252.922 285.764a10.961 10.961 0 0 1 -15.585.843l-83.94-76.386a47.319 47.319 0 0 0 -31.939-12.438z" /></g></svg>
                        </div>
                        <span>Ullamco laboris nisi ut
                          aliquip ex ea commodo consequat.</span>

                      </li>
                      <li>
                        <div className="svg">
                          <svg height="512" viewBox="0 0 520 520" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_7-Check" data-name="7-Check"><path d="m79.423 240.755a47.529 47.529 0 0 0 -36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515l250.787-403.892c.041-.067.084-.134.128-.2 2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0 -19.362 1.343q-.135.166-.278.327l-252.922 285.764a10.961 10.961 0 0 1 -15.585.843l-83.94-76.386a47.319 47.319 0 0 0 -31.939-12.438z" /></g></svg>
                        </div>
                        <span>Duis aute irure dolor in
                          reprehenderit in voluptate velit.</span>

                      </li>
                      <li>
                        <div className="svg">
                          <svg height="512" viewBox="0 0 520 520" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_7-Check" data-name="7-Check"><path d="m79.423 240.755a47.529 47.529 0 0 0 -36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515l250.787-403.892c.041-.067.084-.134.128-.2 2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0 -19.362 1.343q-.135.166-.278.327l-252.922 285.764a10.961 10.961 0 0 1 -15.585.843l-83.94-76.386a47.319 47.319 0 0 0 -31.939-12.438z" /></g></svg>
                        </div>


                        <span>Ullamco laboris nisi ut
                          aliquip ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate trideta storacalaperda mastiro dolore
                          eu fugiat nulla pariatur.</span>

                      </li>
                    </ul> */}
                    <p>

                      Their core values include a focus on quality, ensuring all products meet the highest safety and regulatory standards while exceeding customer expectations. They prioritize innovation to address unmet medical needs and enhance patient outcomes. Operating with integrity, they conduct business transparently, earning the trust of customers, partners, and stakeholders. Additionally, VAK International puts customers first, tailoring solutions to meet their healthcare needs effectively.

                    </p>

                    <p>Specializing in pharmaceuticals and nutraceuticals, VAK International provides a wide range of products to treat various medical conditions and support overall health and well-being. They are committed to sustainability, implementing eco-friendly practices to reduce environmental impact and promote sustainable manufacturing processes.
                    </p>

                    <p>Based in Delhi, VAK International operates globally, serving customers in diverse markets worldwide through strategic partnerships and alliances. The company's dedication to innovation, quality, and customer satisfaction has positioned them as a trusted provider of pharmaceutical and nutraceutical products since their inception in 2023. Their focus remains on enhancing lives through healthcare excellence and contributing to positive global health outcomes.</p>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </section>
          {/* End About Us Section */}

          {/* ======= Counts Section ======= */}
          <section id="products" className="counts">
            <div className="section-title">
              <h2>Products</h2>
            </div>
            <div className="container" data-aos="fade-up">
              <div className="row no-gutters">
                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p1} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Emarco</h4>


                      </div>

                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p2} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Betkas-Plus</h4>


                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p3} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Bmarco Forte</h4>


                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p4} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Beklair</h4>


                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p5} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Vakion-400</h4>


                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p6} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Callaxo Plus</h4>


                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p7} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Qmarco</h4>


                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="count">
                  <AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
                    <div className="count-box">
                      <div className="product-img">
                        <img src={p8} alt="product1" />
                      </div>
                      <div className="product-body">
                        <h4>Beveles</h4>


                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

              </div>
            </div>
          </section>
          {/* End Counts Section */}
          {/* ======= Cta Section ======= */}
          <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">
              <AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
                <div className="text-center">
                  <h3>Want to know more?</h3>
                  <p>
                    {" "}
                    For any queries -

                  </p>
                  <a class="custom-btn btn-13" href='#contact'>Contact Us</a>

                </div>
              </AnimationOnScroll>
            </div>
          </section>
          {/* End Cta Section */}
          {/* ======= Testimonials Section ======= */}
          {/* <section className="testimonials">
            <div className="section-title">
              <h2>Testimonials</h2>
            </div>
            <AnimationOnScroll animateIn="animate__slideInRight" animateOnce={true}>
              <div className="testimonials-body">
                <figure class="snip1192">
                  <blockquote>Calvin: Sometimes when I'm talking with others, my words can't keep up with my thoughts. I wonder why we think faster than we speak. Hobbes: Probably so we can think twice. </blockquote>
                  <div class="author">
                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample1.jpg" alt="sq-sample1" />
                    <h5>Pelican Steve <span> LittleSnippets</span></h5>
                  </div>
                </figure>
                <figure class="snip1192 hover">
                  <blockquote>Thank you. before I begin, I'd like everyone to notice that my report is in a professional, clear plastic binder...When a report looks this good, you know it'll get an A. That's a tip kids. Write it down.</blockquote>
                  <div class="author">
                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample24.jpg" alt="sq-sample24" />
                    <h5>Max Conversion<span> LittleSnippets</span></h5>
                  </div>
                </figure>
                <figure class="snip1192">
                  <blockquote>My behaviour is addictive functioning in a disease process of toxic co-dependency. I need holistic healing and wellness before I'll accept any responsibility for my actions.</blockquote>
                  <div class="author">
                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample29.jpg" alt="sq-sample29" />
                    <h5>Eleanor Faint<span> LittleSnippets</span></h5>
                  </div>
                </figure>
              </div>
            </AnimationOnScroll>
          </section> */}

          {/* End Testimonials Section */}
          {/* ======= Gallery Section ======= */}
          <section id="gallery" className="gallery">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Gallery</h2>
              </div>

              <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                autoPlay={true}
                infinite={true}
                autoPlayInterval={1500}
                renderPrevButton={() => {
                  return <svg className='prev' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                }}
                renderNextButton={() => {
                  return <svg className='next' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                }}
              />

              <div className="zoom" style={{ display: closeZoom }}>
                <svg onClick={close} id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm106.38 334.1c7.81 7.81 7.81 20.47 0 28.28s-20.47 7.81-28.28 0l-78.1-78.1-78.1 78.1c-7.81 7.81-20.47 7.81-28.28 0s-7.81-20.47 0-28.28l78.1-78.1-78.1-78.1c-7.81-7.81-7.81-20.47 0-28.28s20.47-7.81 28.28 0l78.1 78.1 78.1-78.1c7.81-7.81 20.47-7.81 28.28 0s7.81 20.47 0 28.28l-78.1 78.1z" fill="rgb(0,0,0)" /></svg>
                <img src={imageSrc} alt="gallery-zoom" />
              </div>
            </div>
          </section>

          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact">
            <div className="container">
              <div className="section-title">
                <h2>Contact</h2>
              </div>
            </div>

            <div className="container">
              <div className="row mt-5">
                <div className="col-lg-6">
                  <AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="info-box">
                          <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                          <h3>Our Address</h3>
                          <p style={{ width: "80%", margin: "auto" }}>Second Floor , A 156/1, Rewari Line Industrial Area Phase II, Mayapuri, New Delhi - 11064</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="info-box mt-4">
                          <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M64 208.1L256 65.9 448 208.1v47.4L289.5 373c-9.7 7.2-21.4 11-33.5 11s-23.8-3.9-33.5-11L64 255.5V208.1zM256 0c-12.1 0-23.8 3.9-33.5 11L25.9 156.7C9.6 168.8 0 187.8 0 208.1V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V208.1c0-20.3-9.6-39.4-25.9-51.4L289.5 11C279.8 3.9 268.1 0 256 0z" /></svg>
                          <h3>Email Us</h3>
                          <p>

                            vakaccnts@gmail.com

                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="info-box mt-4">
                          <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                          <h3>Call Us</h3>
                          <p>+91 93159 14499</p>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="col-lg-6">
                  <AnimationOnScroll animateIn="animate__slideInRight" animateOnce={true}>
                    <form
                      action="POST" ref={form}
                      className="php-email-form"
                    >
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Your Name"
                            name='Name'
                            value={userData.Name}
                            onChange={postUserData}
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input
                            type="email"
                            className="form-control"
                            name='Email'
                            value={userData.Email}
                            onChange={postUserData}
                            required
                            id="email"
                            placeholder="Your Email"

                          />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <input
                          type="text"
                          className="form-control"
                          name='Subject'
                          value={userData.Subject}
                          onChange={postUserData}
                          required
                          id="subject"
                          placeholder="Subject"

                        />
                      </div>
                      <div className="form-group mt-3">
                        <textarea
                          className="form-control"
                          name='Message'
                          value={userData.Message}
                          onChange={postUserData}
                          required
                          rows={7}
                          placeholder="Message"

                          defaultValue={""}
                        />
                      </div>

                      <div className="text-center" style={{ marginTop: "2%" }} >
                        <button type="submit" onClick={SubmitData}>Send Message</button>
                      </div>
                    </form>
                  </AnimationOnScroll>
                </div>

              </div>
            </div>
          </section>
          {/* End Contact Section */}
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <Footer />
        {/* End Footer */}
      </>
      {/* <Footer /> */}
    </div >
  )
}


export default Home
