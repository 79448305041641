import React, { useEffect, useState } from 'react'
import "./PrivacyPolicy.scss"
import Header from '../../Utilities/Header/Header'
import Footer from '../../Utilities/Footer/Footer'
import { Link } from 'react-router-dom'
import logo from "../../Images/logo/logo transparent.png"

const About = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const up = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setnavMobileshow("none");
    setMenuIcon("block")
  }

  const [navMobileshow, setnavMobileshow] = useState("none");

  const navMobileopen = () => {
    setnavMobileshow("block");
    setMenuIcon("none")
  }

  const navMobileclose = () => {
    setnavMobileshow("none");
    setMenuIcon("block")
  }

  const [menuicon, setMenuIcon] = useState("");


  return (

    <>

      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <Link to={"/"} className="logo me-auto" onClick={up}>
            <img src={logo} alt="logo" />
          </Link>

          <nav id="navbar" className="navbar order-last order-lg-0">

            <div className="navbar-links">
              <Link to={"/"} onClick={up}> Home</Link>
              <Link to={"/#about"}>About</Link>
              <Link to={"/#products"}>Products</Link>
              <Link to={"/#gallery"}>Gallery</Link>
              <Link to={"/#contact"}>Contact</Link>
            </div>

            <svg style={{ display: menuicon }} onClick={navMobileopen} className='menu' id="Capa_1" enable-background="new 0 0 464.205 464.205" height="512" viewBox="0 0 464.205 464.205" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="grip-solid-horizontal_1_"><path d="m435.192 406.18h-406.179c-16.024 0-29.013-12.99-29.013-29.013s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.99 29.013 29.013-.001 16.023-12.99 29.013-29.014 29.013z" /><path d="m435.192 261.115h-406.179c-16.024 0-29.013-12.989-29.013-29.012s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.989 29.013 29.013s-12.99 29.012-29.014 29.012z" /><path d="m435.192 116.051h-406.179c-16.024 0-29.013-12.989-29.013-29.013s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.989 29.013 29.013s-12.99 29.013-29.014 29.013z" /></g></g></svg>
          </nav>

          <div className="nav-mobile" style={{ display: navMobileshow }}>

            <svg onClick={navMobileclose} className='close' id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>
            <div className="nav-mobile-links">
              <Link to={"/"} onClick={up}>Home</Link>
              <Link to={"/#about"} onClick={navMobileclose}>About</Link>
              <Link to={"/#products"} onClick={navMobileclose}>Products</Link>
              <Link to={"/#gallery"} onClick={navMobileclose}>Gallery</Link>
              <Link to={"/#contact"} onClick={navMobileclose}>Contact</Link>
            </div>

          </div>

        </div>
      </header>

      <div className="privacy-policy">

        <div className="section-title">
          <h2>Privacy Policy</h2>
        </div>

        <p>Last Updated: 5 DEC 2023</p>
        <p>Welcome to Akcent Healthcare Pvt Ltd ("us", "we", or "our"). Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website.</p>

        <ol>
          <li>Information We Collect:</li>

          <ul>
            <li>Personal Information: <br />
              <p>We may collect personally identifiable information, such as your name, contact details, email address, quantity, etc when you voluntarily submit it through our contact forms or other interactions.</p>
            </li>

            <li>Usage Data:<br />
              <p>We may collect information on how the website is accessed and used ("Usage Data"). This data may include your computer's Internet Protocol address, browser type, browser version, the pages you visit, the time and date of your visit, and other diagnostic data.</p>
            </li>
          </ul>

          <li>How We Use Your Information</li>

          <ul>
            <li>Personal Information: <br />
              <p>We may use your personal information to contact you with newsletters, marketing, or promotional materials, and other information that may be of interest to you.</p>
            </li>

            <li>Usage Data:<br />
              <p>We use Usage Data to analyze and improve the functionality and content of our website, to understand user preferences, and to enhance user experience.</p>
            </li>
          </ul>

          <li>Cookies and Tracking Technologies</li>

          <ul>
            <li>Cookies: <br />
              <p>We use cookies to track the activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
            </li>
          </ul>

          <li>Third-Party Links</li>

          <ul>
            <li>
              External Websites:<br />
              <p>Our website may contain links to external sites that are not operated by us. We have no control over and assume no responsibility for, the content, privacy policies, or practices of any third-party sites.</p>
            </li>

          </ul>

          <li>Data Security</li>

          <ul>
            <li>
              Security Measures:<br />
              <p>We employ industry-standard security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>
            </li>

          </ul>

          <li>Changes to This Privacy Policy</li>

          <ul>
            <li>
              Updates:<br />
              <p>We reserve the right to update our Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            </li>

          </ul>

          <li>Contact Us</li>

          <ul>
            <li>
              Questions:<br />
              <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a style={{color: "#000"}} href="mailto:akcenthealthcare@gmail.com">akcenthealthcare@gmail.com</a> </p>
            </li>

          </ul>

        </ol>
        <p>By using our website, you agree to the collection and use of information by following this Privacy Policy. </p>
        <p>Please read this Privacy Policy carefully and ensure that you understand it. Your continued use of our website constitutes your acceptance of this Privacy Policy and any updates.</p>
      </div>
      <footer id="footer">

        <div className="footer-top">
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="circle3"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-img">
                  <img src={logo} alt="logo" />

                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>Vak International</h3>
                  <p>VAK International Pharmaceuticals was founded in 2023 by Vibha Singh with a mission to improve global health by offering top-notch pharmaceutical and nutraceutical products.</p>
                  {/* <div className="social-links mt-3">

    <Link to="#" className="facebook">
      <img src={facebook} alt="" />
    </Link>
    <Link to="#" className="instagram">
      <img src={instagram} alt="" />
    </Link>
    <Link to="#" className="google-plus">
      <img src={google} alt="" />
    </Link>
    <Link to="#" className="linkedin">
      <img src={linkedin} alt="" />
    </Link>
  </div> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg><Link to="/" onClick={up}>Home</Link>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <Link to={"/#about"}>About</Link>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <Link to={"/#products"}>Products</Link>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <Link to={"/#gallery"}>Gallery</Link>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <Link to={"/#contact"}>Contact</Link>
                  </li>

                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg>{" "}
                    <Link to={"/privacy-policy"}>Privacy policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <div className="svg">
                      <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                    </div>
                    <a href="tel:919315914499"> +91 93159 14499 </a>
                  </li>
                  <li>
                    <div className="svg">
                      <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M64 208.1L256 65.9 448 208.1v47.4L289.5 373c-9.7 7.2-21.4 11-33.5 11s-23.8-3.9-33.5-11L64 255.5V208.1zM256 0c-12.1 0-23.8 3.9-33.5 11L25.9 156.7C9.6 168.8 0 187.8 0 208.1V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V208.1c0-20.3-9.6-39.4-25.9-51.4L289.5 11C279.8 3.9 268.1 0 256 0z" /></svg>
                    </div>
                    <a href="mailto:akcenthealthcare@gmail.com" style={{ textTransform: 'lowercase' }}>akcenthealthcare@gmail.com</a>
                  </li>
                  <li>
                    <div className="svg">
                      <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                    </div>

                    <a target='_blank' href="https://www.google.com/maps?ll=28.649983,77.145366&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=6992852429798267520">Second Floor , A 156/1, Rewari Line Industrial Area Phase II, Mayapuri, New Delhi - 11064</a>
                  </li>
                </ul>
              </div>
              {/* <div className="col-lg-4 col-md-6 footer-newsletter">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7002.650195461083!2d77.145366!3d28.649983000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d033a653f51a9%3A0x610b9a3c34da5a80!2sAkcent%20Healthcare%20(INDIA)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1699012440986!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

</div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>AHS Assured Services</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  )
}

export default About
