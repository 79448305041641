import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from "../../Images/logo/logo transparent.png"
import "./Header.scss"

const Header = () => {

    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setnavMobileshow("none");
        setMenuIcon("block")
    }

    const [navMobileshow, setnavMobileshow] = useState("none");

    const navMobileopen = () => {
        setnavMobileshow("block");
        setMenuIcon("none")
    }

    const navMobileclose = () => {
        setnavMobileshow("none");
        setMenuIcon("block")
    }

    const [menuicon, setMenuIcon] = useState("");



    return (

        <>
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center">
                    <Link to={"/"} className="logo me-auto" onClick={up}>
                        <img src={logo} alt="logo" />
                    </Link>

                    <nav id="navbar" className="navbar order-last order-lg-0">

                        <div className="navbar-links">
                            <Link to={"/"} onClick={up}> Home</Link>
                            <a href="#about">About</a>
                            <a href="#products">Products</a>
                            <a href="#gallery">Gallery</a>
                            <a href="#contact">Contact</a>
                        </div>

                        <svg style={{ display: menuicon }} onClick={navMobileopen} className='menu' id="Capa_1" enable-background="new 0 0 464.205 464.205" height="512" viewBox="0 0 464.205 464.205" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="grip-solid-horizontal_1_"><path d="m435.192 406.18h-406.179c-16.024 0-29.013-12.99-29.013-29.013s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.99 29.013 29.013-.001 16.023-12.99 29.013-29.014 29.013z" /><path d="m435.192 261.115h-406.179c-16.024 0-29.013-12.989-29.013-29.012s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.989 29.013 29.013s-12.99 29.012-29.014 29.012z" /><path d="m435.192 116.051h-406.179c-16.024 0-29.013-12.989-29.013-29.013s12.989-29.013 29.013-29.013h406.18c16.023 0 29.013 12.989 29.013 29.013s-12.99 29.013-29.014 29.013z" /></g></g></svg>
                    </nav>

                    <div className="nav-mobile" style={{ display: navMobileshow }}>

                        <svg onClick={navMobileclose} className='close' id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>
                        <div className="nav-mobile-links">
                            <Link to={"/"} onClick={up}>
                                Home
                            </Link>

                            <a href="#about" onClick={navMobileclose}>
                                About</a>
                            <a href="#products" onClick={navMobileclose}>Products</a>
                            <a href="#gallery" onClick={navMobileclose}>Gallery</a>
                            <a href="#contact" onClick={navMobileclose}>Contact</a>
                        </div>

                    </div>

                </div>
            </header>
        </>
    )
}

export default Header
