import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../Images/logo/logo transparent.png"

const Footer = () => {

  const up = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <div>
      <footer id="footer">

        <div className="footer-top">
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="circle3"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-img">
                  <img src={logo} alt="logo" />

                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>Vak International</h3>
                  <p>VAK International Pharmaceuticals was founded in 2023 by Vibha Singh with a mission to improve global health by offering top-notch pharmaceutical and nutraceutical products.</p>
                  {/* <div className="social-links mt-3">

            <a href="#" className="facebook">
              <img src={facebook} alt="" />
            </a>
            <a href="#" className="instagram">
              <img src={instagram} alt="" />
            </a>
            <a href="#" className="google-plus">
              <img src={google} alt="" />
            </a>
            <a href="#" className="linkedin">
              <img src={linkedin} alt="" />
            </a>
          </div> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg><Link to="/" onClick={up}>Home</Link>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <a href="#about">About</a>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <a href="#products">Products</a>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <a href="#gallery">Gallery</a>
                  </li>
                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg> <a href="#contact">Contact</a>
                  </li>

                  <li>
                    <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><g id="icon"><path id="XMLID_195_" d="m86.691 64.002c0 1.45-.591 2.841-1.638 3.844l-34.706 33.373c-1.035.996-2.369 1.491-3.701 1.491-1.4 0-2.799-.547-3.848-1.638-2.044-2.126-1.979-5.506.147-7.549l30.703-29.521-30.702-29.524c-2.126-2.043-2.192-5.421-.147-7.55 2.043-2.123 5.424-2.189 7.549-.146l34.706 33.37c1.046 1.007 1.637 2.395 1.637 3.85z" /></g></svg>{" "}
                    <Link to={"/privacy-policy"}>Privacy policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <div className="svg">
                      <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                    </div>
                    <a href="tel:919315914499"> +91 93159 14499 </a>
                  </li>
                  <li>
                    <div className="svg">
                      <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M64 208.1L256 65.9 448 208.1v47.4L289.5 373c-9.7 7.2-21.4 11-33.5 11s-23.8-3.9-33.5-11L64 255.5V208.1zM256 0c-12.1 0-23.8 3.9-33.5 11L25.9 156.7C9.6 168.8 0 187.8 0 208.1V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V208.1c0-20.3-9.6-39.4-25.9-51.4L289.5 11C279.8 3.9 268.1 0 256 0z" /></svg>
                    </div>
                    <a href="mailto:vakaccnts@gmail.com" style={{ textTransform: 'lowercase' }}>vakaccnts@gmail.com</a>
                  </li>
                  <li>
                    <div className="svg">
                      <svg className='svg-2' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                    </div>

                    <a target='_blank' href="https://www.google.com/maps?ll=28.649983,77.145366&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=6992852429798267520">Second Floor , A 156/1, Rewari Line Industrial Area Phase II, Mayapuri, New Delhi - 11064</a>
                  </li>
                </ul>
              </div>
              {/* <div className="col-lg-4 col-md-6 footer-newsletter">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7002.650195461083!2d77.145366!3d28.649983000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d033a653f51a9%3A0x610b9a3c34da5a80!2sAkcent%20Healthcare%20(INDIA)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1699012440986!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>AHS Assured Services</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
